import { ErrorPageView } from '@/components/ErrorPageView'
import TopNav from '@/components/TopNav'
import { BaseContainer } from '@/components/containers/BaseContainer'
import { ErrorDetails } from '@grandstand-web/bally-web-shared/src/type/Error'
import { NextPageContext } from 'next'
import { ErrorProps } from 'next/error'

const Error = ({ statusCode }: ErrorProps) => {
  const error = { code: 'default_error' } as ErrorDetails
  if (statusCode === 404) {
    error.code = 'not_found'
  }
  return (
    <>
      <BaseContainer>
        <TopNav />
      </BaseContainer>
      <ErrorPageView error={error} />
    </>
  )
}

Error.getInitialProps = ({ res, err }: NextPageContext): Promise<ErrorProps> | ErrorProps => {
  const statusCode = res?.statusCode ?? err?.statusCode ?? 500
  return { statusCode }
}

export default Error
